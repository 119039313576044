import { useLocation, useNavigate } from "react-router-dom";

import { useData } from "../context"

import Text from "../components/Elements/Text/Text";

import './styles/Page404.css';

function Page404() {

    const navigate = useNavigate();

    const { content, language, setDisposition } = useData();
    const location = useLocation();
    setDisposition(location.pathname);

    return (
        <div className="page404">
            <img src={require("../media/img/404.png")} alt="" />

            <Text small={"page not found"} size_s="24px" fontWeight_s={500} />

            <button className="orange" onClick={() => navigate(`/`)}> {content && content[language]?.button404}</button>
        </div>
    )
};

export default Page404;
