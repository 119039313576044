import { useRef } from "react";
import { useData } from "../../../../context"

import "./Promocode.css"

const Promocode = () => {

    const {setPromocode, content, language, addNotifi, notifiTypes} = useData();
    const inputRef = useRef(null);

    const handleClick = () => {
        const code = inputRef.current.value;
        
        if(!code){
            const notifi = {
                type: notifiTypes.error,
                time: 2000,
                text: content[language].notifi_empty_promocode,
            }

            addNotifi(notifi);
            return
        }
        setPromocode({value: code});
    }


    return (
        <div className="promocode">
            <span className="modale_title">{content[language].modale_poromocode}</span>
            <span>{content[language].modale_poromocode_title}</span>

            <input ref={inputRef} id="promocode" type="text" placeholder={content[language].promocode_field}/>

            <button className="orange" onClick={handleClick}>{content[language].send_promo_button}</button>

        </div>
    )
}

export default Promocode;