import { useEffect } from "react";

import { useData } from "../../context";

import NotifiComponent from "./components/NotifiComponent/NotifiComponent";

import "./Notifi.css";

const Notifi = () => {

    const { notifiList, notifiQueue, startTimer, setNotifiQueue, setNotifiList } = useData();

    useEffect(() => {
        if (notifiList.length < 2 && notifiQueue.length > 0) {
            for (let i = 2 - notifiList.length; i > 0; i--) {
                if (notifiQueue.length > 0 && notifiQueue[0]) {
                    setNotifiList([...notifiList, notifiQueue[0]]);
                    setNotifiQueue(prevQueue =>{
                        prevQueue.shift();
                        const newQueue = [...prevQueue];
                        return newQueue;
                    });
                }
            }
        }
    }, [notifiList.length, notifiQueue.length]);

    useEffect(() => {
        startTimer();
    }, []);

    return (
        <div className="notifi">
            {notifiList.map((el) => {
                return <NotifiComponent el={el}/>
            })}
        </div>
    );
}

export default Notifi;