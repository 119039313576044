import { useLocation, useNavigate } from "react-router-dom";
import { useData } from "../context"

import YouTube from "react-youtube";

import Card from "../components/Elements/Card/Card";
import Partners from "../components/Partners/Partners";
import Text from "../components/Elements/Text/Text";

import "./styles/Content.css";

function Content() {

  const navigate = useNavigate();

  const { streamPage, setStreamPage, streamersList, content, language, setDisposition } = useData();

  setDisposition(useLocation().pathname);
  setStreamPage(null);
  return (
    <div className={`content ${streamPage ? "pageOpened" : ""}`}>
      <div className="preview block" data-wow-delay="0.2s" >
        <div className="info">
          <p className="title">{content && content[language]?.preview_title}</p>
          <p className="preview_info">{content && content[language]?.preview_text}</p>
          <button className="orange" onClick={() => {
            if (streamersList) {
              setStreamPage(streamersList[0]);
              navigate(`/streamer?media=${streamersList[0]?.name}`);
            }
          }}
          >{content && content[language]?.preview_button}</button>
        </div>
        <div className="stream">
          <YouTube
            videoId={`${streamersList ? streamersList[0]?.stream_link : ""}`}
            opts={{
              width: "100%",
              height: "100%",
              playerVars: {
                autoplay: 1,
                mute: 1,
              }
            }} />
        </div>
      </div>
      <div className="streamers block" data-wow-delay="0.2s">
        <Text big={content && content[language]?.streamers_title} small={content && content[language]?.streamers_text} />
        <div className="cards">
          {streamersList && streamersList.length > 0 && streamersList.map((streamer, id) => {
            return <Card online={streamer.event} img={streamer.preview} text={streamer.name} code={streamer.promo} onClick={() => { navigate(`/streamer?media=${streamer?.name}`); setStreamPage(streamer) }} />
          })}
        </div>
      </div>
      <Partners />

    </div>
  );
}

export default Content;

