import { useEffect } from "react";
import { useData } from "../../../../context"


import "./History.css"

const History = () => {

    const { user, userHistory, accessToken, content, language } = useData();

    useEffect(() => {
        userHistory();
    }, [accessToken])


    return (
        <div className="history">
            <span className="modale_title">{content[language].modale_authorization}</span>
            <div className="user_info">
                <img src={user?.photo_url} alt="" />
                <span>{user?.first_name}</span>
            </div>
            <div className="line"></div>
            <div className="prizes_history">
                {
                    user?.history?.length > 0 ?
                        user?.history?.map((prize) => {
                            const date = new Date(prize.timestamp);
                            const today = new Date();
                            let isWinToday = false;
                            if (date.getDate() === today.getDate()
                                && date.getMonth() === today.getMonth()
                                && date.getFullYear() === today.getFullYear())
                                isWinToday = true;

                            return (
                                <div className="user_prize">
                                    <span>{prize.slot.name}</span>
                                    <span>{isWinToday ?
                                        `${date.toLocaleString().split(",")[1].split(":")[0] + ":" + date.toLocaleString().split(",")[1].split(":")[1].split(":")[0]}`
                                        :
                                        `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}</span>
                                </div>
                            )
                        })
                        :
                        <div className="empty_history">
                            {content[language].empty_history}
                        </div>
                }

            </div>
            <div className="line"></div>
            <a href="https://t.me/authtest123_bot" target="_blank">
                <button className="orange">
                    {content && (content[language]?.contact_support_button || "call to support")}
                </button>
            </a>


        </div>
    )
}

export default History;