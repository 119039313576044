

function Partner({ img, name, link, link_name, link_text }) {
    return (
        <a href={link} target='_blank'>
            <div className="partner">
                <div className="partner_img">
                    <img src={img} alt="img"  width="auto" />
                </div>
            </div>
        </a>
    )
}

export default Partner; 