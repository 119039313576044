import TelegramLoginButton from 'react-telegram-login';

import { useData } from '../../../../context';

import "./Authorization.css"

const Authorization = () => {

    const { setUser, getToken, setModaleType, content, language } = useData();

    const handleTelegramResponse = response =>{
        setUser(response);
        localStorage.setItem("user", JSON.stringify(response));
        getToken(response);
        setModaleType(null);
    }

    return (
        <div className="authorization">
            <span className="modale_title">{content[language].modale_authorization}</span>
            <div className="line"></div>
            <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="authtest123_bot" usePic="false"  />
        </div>
    )
}

export default Authorization;