import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useData } from "../context"

import Partners from "../components/Partners/Partners";

import './styles/Bonus.css';

function Bonus() {

  const { content, getContent, language, setDisposition, winners, getWinners, slots, getSlots, setModaleType, user, promocode, rollIsActive, bestDrop } = useData();

  setDisposition(useLocation().pathname);

  useEffect(() => {
    getWinners();
    getSlots();
    getContent();
  }, []);

  useEffect(() => {

    const canvas = document.getElementById('wheelCanvas');
    const ctx = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = canvas.width * 0.85178875 / 2;  // Радиус круга
    const slotsValue = slots.length;
    const angleStep = (2 * Math.PI) / slotsValue;
    document.documentElement.style.setProperty("--angle-step", `${Math.round(angleStep * 180 / Math.PI)}`);

    function drawSegment(index, startAngle, endAngle, gradient1, gradient2) {
      const gradient = ctx.createLinearGradient(centerX, centerY, Math.cos((endAngle + startAngle) / 2) * radius + radius, Math.sin((startAngle + endAngle) / 2) * radius + radius);
      gradient.addColorStop(0, gradient1);
      gradient.addColorStop(0.5, gradient1);
      gradient.addColorStop(1, gradient2);

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, radius, startAngle, endAngle);
      ctx.closePath();
      ctx.fillStyle = gradient;

      const gradientStroke = ctx.createLinearGradient(centerX, centerY, Math.cos((endAngle + startAngle) / 2) * radius + radius, Math.sin((startAngle + endAngle) / 2) * radius + radius);
      gradientStroke.addColorStop(0, "#ffffff00");
      gradientStroke.addColorStop(0.2, "#ffffff00");
      gradientStroke.addColorStop(0.4, "#ffffffbf ");
      gradientStroke.addColorStop(0.75, "#ffffff00");
      gradientStroke.addColorStop(1, "#ffffff00");

      ctx.strokeStyle = gradientStroke;  // Градиент для обводки
      ctx.lineWidth = 3;
      ctx.fill();
      ctx.stroke();
    }
    function drawText(index, angle) {
      const text = slots[index].name;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(angle);
      ctx.textAlign = 'end';
      ctx.fillStyle = "white";
      ctx.font = "20px Oldtimer";
      ctx.fillText(text, radius - 20, 10);
      ctx.restore();
    }

    // Рисование колеса
    for (let i = 0; i < slotsValue; i++) {
      const startAngle = i * angleStep;
      const endAngle = (i + 1) * angleStep;
      const gradient1 = i % 2 === 0 ? "#2E1A09" : "#2E1A09";  // Чередование цветов
      const gradient2 = i % 2 === 0 ? "#9F6623" : "#FFA53C";

      const color = i % 2 === 0 ? "#9F6623" : "#FFA53C";

      drawSegment(i, startAngle, endAngle, gradient2, gradient1);
      drawText(i, startAngle + angleStep / 2);
    }
  }, [slots])

  const handleClick = () => {

    if (!rollIsActive) {
      user ?
        setModaleType('promocode')
        : setModaleType('authorization')
    }
  };

  return (
    <div className="bonus">

      {bestDrop && <div className="winner bestDrop">
        <img src={require("../media/img/bestDrop.png")} alt="" className="border_img" />
        <img src={bestDrop.slot_image} className="prize_img" />
        <span>{bestDrop.username}</span>
        <p>{(content && content[language].best_prize || "лучший приз").toUpperCase()}</p>
      </div>}
      <div className="winners">
        <div className="winners_scroll">
          {winners && winners.map((prize, index) => {
            return (
              <div className="winner" key={prize + index}>
                <img src={require("../media/svg/prize_border.svg").default} alt="" className="border_img" />
                <img src={prize.slot_image} className="prize_img" />
                <span>{prize.username}</span>
              </div>
            )
          })}
        </div>

      </div>
      <div className="wheel">
        <img src={require("../media/img/wheel.png")} alt="" />
        <div className="upper_pointer">
          <img src={require("../media/img/pointer.png")} alt="" />
        </div>
        <div className="center_button" onClick={() => { handleClick(); }}>
          {content && content[language].spin_button_top}<br /><p>{content && content[language].spin_button_bottom?.toUpperCase()}</p>
        </div>
        <canvas id="wheelCanvas" width="500" height="500"></canvas>
      </div>
      <Partners />
    </div>
  )
};

export default Bonus;
