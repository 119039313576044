
import "./Text.css"

function Text ({big, size_b = "64px", fontWeight_b = 500, lh_big = "130%", small, size_s = "22px", fontWeight_s = 400, lh_small = "140%", gap= "20px"}){
    return(
        <div className="text" >
            <p className="title" style={{ '--fontSize_b':size_b, '--fontWeight_b': fontWeight_b, '--lineHeight_b':lh_big, '--marginBottom_b':gap }}>{big}</p>
            <p className="title_text" style={{ '--fontSize_s':size_s, '--fontWeight_s': fontWeight_s, '--lineHeight_s':lh_small }}>{small}</p>
        </div>
    )
}

export default Text;